.shadow {
  box-shadow: 0 .2rem 1rem .2rem var(--box-shadow-color)!important;
}

.primary-color {
  color: var(--bs-primary);
}

.fill-body-color {
  fill: var(--bs-body-color);
}

a:hover {
  color: var(--bs-primary);
}
a {
  color: var(--link-color);
}

.btn-primary{
  color: var(--primary-button-font-color);
  background-color: var(--bs-primary);
  border-top-color: var(--bs-primary);
  border-bottom-color: var(--bs-primary);
  border-left-color: var(--bs-primary);
  border-right-color: var(--bs-primary);
}

.btn-primary:hover {
  color: var(--primary-button-font-color);
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.letter-spacing-2{
  letter-spacing: 2px;
}

.letter-spacing-4{
  letter-spacing: 4px;
}

.pt-section {
  padding-top: 5rem!important;
}

