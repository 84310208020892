:root {
  --principium-gold: #f9c10a;
  --principium-gold-rgb: 249, 193, 10;
  --super-light-gray: #eee;
  --light-gray: #a0a0a0;
  --light-gray-invert: #5f5f5f;
  --gray: #555;
  --gray-invert: #aaaaaa;
  --dark-gray: #333;
  --dark-gray-invert: #cccccc;
  --facebook-blue: #4267b2;
  --instagram-magenta: #ed4855;
  --youtube-red: #ff0000;
  --bs-body-font-family: "Montserrat" !important;
  --bs-body-color: #000 !important;
  --bs-primary: var(--principium-gold) !important;
  --bs-primary-rgb: var(--principium-gold-rgb) !important;
  --box-text-shadow-color: var(--gray);
}

body {
  --bs-primary: var(--bs-yellow) !important;
  --primary-button-font-color: white;
  --link-color: var(--gray);
  --font-color: var(--gray);
  --headline-color: var(--dark-gray);
  --text-shadow-color: white;
  --navbar-background-color: white;
  --navbar-link-color: #000;
  --background-color: #fff;
  --background-color-rgb: 255, 255, 255;
  --bs-body-bg-rgb: var(--background-color-rgb) !important;
  --bs-body-bg: var(--background-color) !important;
  --navbar-brand-color: black;
  --box-shadow-color: #00000025;
  --website-carbon-badge-bg-1:var(--bs-primary);
  --website-carbon-badge-bg-2:var(--gray);
}

@media (prefers-color-scheme: dark) {
  body {
    --bs-primary: var(--bs-yellow) !important;
    --primary-button-font-color: #212529;
    --link-color: var(--gray-invert);
    --navbar-background-color: #212529;
    --background-color: #212529;
    --text-shadow-color: #212529;
    --font-color: var(--gray-invert);
    --navbar-brand-color: white;
    --navbar-link-color: #fff;
    --headline-color: var(--dark-gray-invert);
    --background-color-rgb: 33, 37, 41;
    --background-color: #212529;
    --bs-body-bg-rgb: var(--background-color-rgb) !important;
    --bs-body-bg: var(--background-color) !important;
    --box-shadow-color: #00000050;
    --bs-body-color: #fff !important;
    --website-carbon-badge-bg-1: #212529;
    --website-carbon-badge-bg-2: var(--principium-gold);
  }
}