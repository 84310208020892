@media (min-width: 768px){
  .col-md-6-overflow-left {
    flex: 0 0 auto;
    width: 50%;
    margin-right: -4.17%;
  }

  .col-md-6-overflow-right {
    flex: 0 0 auto;
    width: 58.33333333%;
    margin-left: -4.17%;
  }
}